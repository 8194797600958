import { chakra, Container, Grid, useColorMode } from "@chakra-ui/react";
import { isValidMotionProp, motion, useScroll, useSpring } from "framer-motion";
import React, { useEffect } from "react";
import ContentTeaser from "../components/contentTeaser";
import { HeaderIndex } from "../components/DefinitionComponents/HeaderIndex";
import { Mission } from "../components/DefinitionComponents/MIssion";
import DFN_ParallaxModule from "../components/DFN_ParallaxModule";
import DFN_ParallaxModule2 from "../components/DFN_ParallaxModule2";
import DFN_ParallaxModule3 from "../components/DFN_ParallaxModule3";
import Layout from "../components/layout/Layout";
import ParallaxImageHeader2 from "../components/ParallaxImageHeader2";
import PortfolioGrid from "../components/PortfolioGrid";
import Zwischentitel from "../components/Zwischentitel";
const IndexPage = () => {
	const { setColorMode } = useColorMode();
	useEffect(() => {
		setColorMode("light");
	});
	const ChakraBox = chakra(motion.div, {
		shouldForwardProp: isValidMotionProp,
	});

	const { scrollYProgress } = useScroll();
	// const scaleXa =scrollYProgress;
	const scaleXa = useSpring(scrollYProgress, {
		stiffness: 555,
		damping: 25,
		restDelta: 0.001,
	});

	return (
		<Layout
			title={"Hello a Definition"}
			description={
				"We drive brands. Build platforms. Shape businesses."
			}>
			<ChakraBox
				position="fixed"
				zIndex={89231212174923}
				top="0"
				left="0"
				w="4px"
				minH="100%"
				bg={"brand.violet.primary"}
				style={{
					transformOrigin: "left top",
					scaleY: scaleXa,
				}}></ChakraBox>
			<HeaderIndex />
			<Mission />

			<Container variant="layoutContainer">
				<Zwischentitel
					py={24}
					px={8}
					title="What we do."
					subtitle="From the first idea. to deployment"
					subtitle2="Your strategy will be developed and brought to life in an agile cooperative series of sessions and workshops. We care about your vision and clear the path for your service or product to be delivered flawelessy within your audience."
				/>
			</Container>
			<Container variant="layoutContainer" px={12}>
				<Grid
					w={{ base: "100%", md: "100%" }}
					mx="auto"
					gridGap="1%"
					templateColumns={{
						base: "repeat(1, 1fr)",
						lg: "repeat(12, 1fr)",
					}}
					my={24}
					p={{ base: "1rem", md: "1rem", lg: 0 }}>
					<ContentTeaser
						number={1}
						smallText="Visual and haptics for your brand"
						bigText="Design + Communicate"
						colorSmall="brand.gray.600"
						colorBig="brand.gray.400"
						thirdText="Your brand. We design and develop the core messages of your business and build a framework of visual systematic components to provide a coherent communication with your clients."
					/>
					<ContentTeaser
						number={2}
						smallText="Code, Structure and Infrastructure"
						bigText="Platforms + Development"
						colorSmall="brand.gray.600"
						colorBig="brand.gray.400"
						thirdText="Designing a webapp can be a complex task. As it is to develop backend infrastructures. To integrate ERP and CRM Systems into a digital workflow. "
					/>
					<ContentTeaser
						number={3}
						smallText="Digital business lifecycle."
						bigText="Business + Marketing"
						colorSmall="brand.gray.600"
						colorBig="brand.gray.400"
						thirdText="We connect the dots in your business. We connect platforms, people, products. Turn data into knowledge and enable you to undertake strategic decisions based upon data and knowledge."
					/>
				</Grid>
			</Container>
			<Container variant="layoutContainer">
				<Zwischentitel
					py={24}
					title="Deep Relations"
					subtitle="Brands and people we love."
					subtitle2="Our clients trust us with their businesses. We deliver passionate and thoughtful solutions for international Clients in Europe, Israel and the Unites States"
				/>
			</Container>
			<PortfolioGrid />
			<DFN_ParallaxModule />
			<DFN_ParallaxModule2 />
			<DFN_ParallaxModule3 />
			<Container variant="layoutContainer">
				<Zwischentitel
					py={24}
					title="What we do."
					subtitle="Three pillars. One truth."
					subtitle2="Your strategy will be developed and brought to life in an agile cooperative series of sessions and workshops. We care about your vision and clear the path for your service or product to be delivered flawelessy within your audience."
				/>
			</Container>
			<ParallaxImageHeader2 />
		</Layout>
	);
};

export default IndexPage;
